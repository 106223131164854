














import UserForm from '@/components/auth/UserForm.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { User } from '@/model/user';

export default Vue.extend({
  name: 'SignUp',
  components: {
    UserForm
  },
  data() {
    return {
      saving: false
    };
  },
  computed: {
    ...mapStores(useUsersStore),
    validationErrors(): any {
      return this.usersStore.user.validationErrors;
    }
  },
  methods: {
    async createAccount(dataModelForApi: Partial<User>) {
      this.saving = true;
      const signedUpSuccessfully = await this.usersStore.signUp(
        dataModelForApi
      );
      this.saving = false;
      if (signedUpSuccessfully) {
        await this.$router.push({
          name: 'Home'
        });
      } else {
        this.saving = false;
      }
    }
  }
});
